import React from 'react';
import s from './Item.module.scss';

const Item = ({ icon, children }) => (
    <div className={s.container}>
        <img src={icon} />
        <div className={s.content}>
            {children}
        </div>
    </div>
);

export default Item;
