import React from 'react';
import s from './Footer.module.scss';
import address from './address.svg';
import phone from './phone.svg';

const Footer = () => (
    <div className={s.container}>
        <div className={s.content}>
            <div className={s.address}>
                <img src={address} />
                <div className={s.text}>
                    Дистрибьютор <a className={s.link} href="https://armosystems.ru/contacts/#moscow" target="_blank">АРМО-Системы</a><br/>
                </div>
            </div>
            <div className={s.phone}>
                <img src={phone} />
                <div className={s.text}>
                    <a className={s.phone_number} href="tel:+74957873342">+7 (495) 787-33-42</a>
                </div>
            </div>
            <div className={s.address}>
                <img src={address} />
                <div className={s.text}>
                    Головной офис<br />
                    г. Москва, ул. Бутлерова, д. 17, БЦ<br />
                    NEO GEO, блок Б.
                </div>
            </div>
            <div className={s.phone}>
                <img src={phone} />
                <div className={s.text}>
                    <a className={s.phone_number} href="tel:+74991309892">+7 (499) 130-98-92</a>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
