import React from 'react';
import s from './Section5.module.scss';
import LandingSection from "../../../Components/LandingSection/LandingSection";

const Section5 = () => (
    <LandingSection title="Характеристики">
        <div className={s.items}>
            <div className={s.item}>
                <div className={s.description}>
                    <div className={s.title}>
                        Удаленная рабочая станция
                        Compass WS
                    </div>
                    <div>
                        автоматизированное рабочее место оператора системы видеонаблюдения
                    </div>
                </div>
                <div className={s.features}>
                    <ul>
                        <li>Готовое к использованию в составе системы видеонаблюдения Compass решение</li>
                        <li>Предустановленное программное обеспечение Compass</li>
                    </ul>
                    <button onClick={() => window.open('Compass CWS-TK WS_rev.D.pdf')}>все характеристики</button>
                </div>
            </div>
            <div className={s.item}>
                <div className={s.description}>
                    <div className={s.title}>
                        Устройство цифровой записи Compass NVR
                        Embedded
                    </div>
                    <div>
                        компактный специализированный сетевой видеорегистратор для небольших систем с возможностью масштабирования
                    </div>
                </div>
                <div className={s.features}>
                    <ul>
                        <li>Устройство, объединяющее в себе функции сервера записи и рабочей станции</li>
                        <li>Устройство умещается на рабочем столе</li>
                    </ul>
                    <button onClick={() => window.open('Compass CNVR-TK-E DS_rev.D.pdf')}>все характеристики</button>
                </div>
            </div>
            <div className={s.item}>
                <div className={s.description}>
                    <div className={s.title}>
                        Устройство цифровой записи Compass NVR
                    </div>
                    <div>
                        Специализированный сетевой видеорегистратор для средних и больших систем видеонаблюдения с возможностью масштабирования
                    </div>
                </div>
                <div className={s.features}>
                    <ul>
                        <li>Универсальный корпус 2U для размещения в стандартной стойке 19”</li>
                        <li>Высокая производительность: подключение до 96 IP-камер</li>
                    </ul>
                    <button onClick={() => window.open('Compass CNVR-TK DS_rev.D.pdf')}>все характеристики</button>
                </div>
            </div>
        </div>
    </LandingSection>
);

export default Section5;
