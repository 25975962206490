import React from 'react';
import s from './Header.module.scss';
import logo from "./logo.png";
import background from "./background.png";
import { moveTo } from "../../../Helpers/DomHelper";

const Header = () => (
    <div className={s.container}>
        <div className={s.left}>
            <img src={logo} className={s.logo} alt="logo" />
        </div>
        <div className={s.right}>
            <a className={s.where_buy} href="https://armosystems.ru" target="_blank">Где купить</a>
            <button onClick={() => window.open('https://licenses.compass-dvms.ru/')}>кабинет лицензирования</button>
            {false && <button onClick={() => moveTo('landingForm')}>запросить стоимость</button>}
        </div>
        <div className={s.background}>
            <img src={background} />
            <div className={s.leftGradient} />
            <div className={s.rightGradient} />
        </div>
    </div>
);

export default Header;
