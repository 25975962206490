import React from 'react';
import s from './Item.module.scss';
import cx from "classnames";
import TrackVisibility from "react-on-screen";

class Item extends React.Component {
    state = {
        isVisible: false
    };

    componentDidUpdate(prevProps) {
        const { isVisible, onChange, i } = this.props;

        if (isVisible !== prevProps.isVisible && this.container.getBoundingClientRect().y > 0) {
            onChange(i, isVisible);
            this.setState({ isVisible });
        }
    }

    render() {
        const { children } = this.props;
        const { isVisible } = this.state;

        return <li ref={e => this.container = e} className={cx(s.container, isVisible && s.visible)}>
            {children}
        </li>;
    }
}

const WrappedItem = (props) => <TrackVisibility>
    <Item {...props}/>
</TrackVisibility>;

export default WrappedItem;
