import React from 'react';
import cx from 'classnames';
import s from './Icon.module.scss';

const Icon = ({ title, reverse, three, hover }) => (
    <div className={cx(s.container, {
        [s.reverse]: reverse,
        [s.three]: three,
        [s.hover]: hover
    })}>
            {three ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 245" width="308" height="245">
                    <path id="Ellipse 2" className={s.shp0}
                          d="M133,125c0,38.66 31.34,70 70,70c38.66,0 70,-31.34 70,-70c0,-38.66 -31.34,-70 -70,-70c-38.66,0 -70,31.34 -70,70z"/>
                    <path id="Ellipse 2 copy 2" className={s.shp1}
                          d="M102,121.5c0,55.5 45,100.5 100.5,100.5c55.5,0 100.5,-45 100.5,-100.5c0,-55.5 -45,-100.5 -100.5,-100.5c-55.5,0 -100.5,45 -100.5,100.5z"/>
                    <path id="Ellipse 2 copy 10" className={s.shp3}
                          d="M94,122c0,4.42 3.58,8 8,8c4.42,0 8,-3.58 8,-8c0,-4.42 -3.58,-8 -8,-8c-4.42,0 -8,3.58 -8,8z"/>
                    <path id="Ellipse 2 copy 11" className={s.shp3}
                          d="M129,200c0,4.42 3.58,8 8,8c4.42,0 8,-3.58 8,-8c0,-4.42 -3.58,-8 -8,-8c-4.42,0 -8,3.58 -8,8z"/>
                    <path id="Shape 6 copy 3" className={s.shp4}
                          d="M100,239v-2h-89v2zM135.71,203.71l-1.41,-1.41l-35,35l1.41,1.41z"/>
                    <path id="Ellipse 2 copy 7" className={s.shp4}
                          d="M0,121c0,3.31 2.69,6 6,6c3.31,0 6,-2.69 6,-6c0,-3.31 -2.69,-6 -6,-6c-3.31,0 -6,2.69 -6,6z"/>
                    <path id="Shape 11" className={s.shp4} d="M100,123v-2h-88v2z"/>
                    <path id="Ellipse 2 copy 12" className={s.shp4}
                          d="M0,238c0,3.31 2.69,6 6,6c3.31,0 6,-2.69 6,-6c0,-3.31 -2.69,-6 -6,-6c-3.31,0 -6,2.69 -6,6z"/>
                    <path id="Ellipse 2 copy 13" className={s.shp3}
                          d="M128,46c0,4.42 3.58,8 8,8c4.42,0 8,-3.58 8,-8c0,-4.42 -3.58,-8 -8,-8c-4.42,0 -8,3.58 -8,8z"/>
                    <path id="Shape 6 copy 4" className={s.shp4} d="M11,6v2h89v-2zM100.71,6.29l-1.41,1.41l35,35l1.41,-1.41z"/>
                    <path id="Ellipse 2 copy 14" className={s.shp4}
                          d="M0,7c0,3.31 2.69,6 6,6c3.31,0 6,-2.69 6,-6c0,-3.31 -2.69,-6 -6,-6c-3.31,0 -6,2.69 -6,6z"/>
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 304 207" width="304" height="202">
                    <path
                        id="Ellipse 2"
                        className={s.shp0}
                        d="M31,104c0,38.66 31.34,70 70,70c38.66,0 70,-31.34 70,-70c0,-38.66 -31.34,-70 -70,-70c-38.66,0 -70,31.34 -70,70z"
                    />
                    <path
                        id="Ellipse 2 copy 2"
                        className={s.shp1}
                        d="M1,100.5c0,55.5 45,100.5 100.5,100.5c55.5,0 100.5,-45 100.5,-100.5c0,-55.5 -45,-100.5 -100.5,-100.5c-55.5,0 -100.5,45 -100.5,100.5z"
                    />
                    <path id="Ellipse 2 copy 4" className={s.shp3}
                          d="M192,79c0,4.42 3.58,8 8,8c4.42,0 8,-3.58 8,-8c0,-4.42 -3.58,-8 -8,-8c-4.42,0 -8,3.58 -8,8z"/>
                    <path id="Ellipse 2 copy 10" className={s.shp3}
                          d="M192,122c0,4.42 3.58,8 8,8c4.42,0 8,-3.58 8,-8c0,-4.42 -3.58,-8 -8,-8c-4.42,0 -8,3.58 -8,8z"/>
                    <path id="Ellipse 2 copy 6" className={s.shp4}
                          d="M292,49c0,3.31 2.69,6 6,6c3.31,0 6,-2.69 6,-6c0,-3.31 -2.69,-6 -6,-6c-3.31,0 -6,2.69 -6,6z"/>
                    <path id="Shape 6" className={s.shp4} d="M233,48v2h59v-2zM197.29,83.29l1.41,1.41l35,-35l-1.41,-1.41z"/>
                    <path id="Shape 6 copy 2" className={s.shp4}
                          d="M292,153v-2h-59v2zM232.29,152.71l1.41,-1.41l-35,-35l-1.41,1.41z"/>
                    <path id="Ellipse 2 copy 7" className={s.shp4}
                          d="M292,152c0,3.31 2.69,6 6,6c3.31,0 6,-2.69 6,-6c0,-3.31 -2.69,-6 -6,-6c-3.31,0 -6,2.69 -6,6z"/>
                </svg>}
        <div className={s.text}>
            {title}
        </div>
    </div>
);

export default Icon;
