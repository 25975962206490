import React from 'react';
import s from './Section4.module.scss';
import Icon from "./Item/Icon/Icon";
import LandingSection from "../../../Components/LandingSection/LandingSection";
import cx from 'classnames';
import Item from "./Item/Item";
import adaptive from "../../../Components/adaptive";

class Section4 extends React.Component {
        state = {};
        
        render() {
                const { isMobile } = this.props;

                return (
                    <LandingSection className={s.container} title="Технические особенности">
                        <Item className={s.item} parent={this}>
                            {(hover) => <React.Fragment>
                                {isMobile || <Icon hover={hover} title="01" />}
                                <div className={s.content}>
                                    <div className={s.point}>
                                        <div className={s.title}>
                                            Собственная файловая система
                                        </div>
                                        <div className={s.description}>
                                            недоступность записанных данных для стандартных средств операционной системы, высокая скорость записи, хранение метаданных
                                        </div>
                                    </div>
                                    <div className={s.point}>
                                        <div className={s.title}>
                                            Дополнительные функции отказоустойчивости системы
                                        </div>
                                        <div className={s.description}>
                                            резервирование серверов записи, централизованное хранилище и другие
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>}
                        </Item>
                        <Item className={cx(s.item, s.three)} parent={this}>
                            {(hover) => <React.Fragment>
                                <div className={s.content}>
                                    <div className={s.point}>
                                        Неограниченное количество серверов записи, камер, пользователей, мониторов и т.п.
                                    </div>
                                    <div className={s.point}>
                                        Администрирование всей системы с одного рабочего места
                                    </div>
                                    <div className={s.point}>
                                        Разграничение прав доступа пользователей к различным функциям и компонентам системы
                                    </div>
                                </div>
                                {isMobile || <Icon title="02" three hover={hover} />}
                            </React.Fragment>}
                        </Item>
                        <Item className={s.item} parent={this}>
                            {(hover) => <React.Fragment>
                                {isMobile || <Icon title="03" hover={hover} />}
                                <div className={s.content}>
                                    <div className={s.point}>
                                        <div className={s.title}>
                                            Гибкое программирование
                                        </div>
                                        <div className={s.description}>
                                            связей событий и реакций в системе
                                        </div>
                                    </div>
                                    <div className={s.point}>
                                        Интерактивные масштабируемые графические планы объекта
                                    </div>
                                </div>
                            </React.Fragment>}
                        </Item>
                        <Item className={s.item} parent={this}>
                            {(hover) => <React.Fragment>
                                <div className={s.content}>
                                    <div className={s.point}>
                                        Широкий спектр поддерживаемого оконечного оборудования: совместимость с ONVIF (S)
                                    </div>
                                    <div className={s.point}>
                                        Мультимониторные рабочие станции
                                    </div>
                                </div>
                                {isMobile || <Icon title="04" reverse hover={hover} />}
                            </React.Fragment>}
                        </Item>
                    </LandingSection>
                );
        }
}

export default adaptive(Section4);
