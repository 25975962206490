import React from 'react';
import s from './Section6.module.scss';
import {moveTo} from "../../../Helpers/DomHelper";

const Section6 = () => (
    <div className={s.container}>
        Готовы
        <button onClick={() => moveTo('landingForm')}>
            ЗАКАЗАТЬ COMPASS
        </button>
        уже сейчас?
    </div>
);

export default Section6;
