import React from 'react';
import s from './Section2.module.scss';
import LandingSection from "../../../Components/LandingSection/LandingSection";
import icon1 from './Icons/1.png';
import icon2 from './Icons/2.png';
import icon3 from './Icons/3.png';
import icon4 from './Icons/4.png';
import Item from "./Item/Item";

const Section2 = () => (
    <LandingSection className={s.container} title="Ключевые особенности">
        <div className={s.items}>
            <Item icon={icon1}>
                Возможность выбора схемы внедрения
            </Item>
            <Item icon={icon2}>
                Применение уникальных технических решений
            </Item>
            <Item icon={icon3}>
                Гибкая политика лицензирования
            </Item>
            <Item icon={icon4}>
                Возможность адаптации под требования заказчиков
            </Item>
        </div>
        <ul>
            <li>
                Архитектурная направленность на создание больших систем видеонаблюдения
            </li>
            <li>
                Ориентированность на широкий спектр отраслей, таких как промышленность, транспорт, торговля
            </li>
            <li>
                Простая интеграция и взаимодействие с другими системами: СВН, СКУД, ОПС, ATM/POS, системами автоматизации
            </li>
            <li>
                Протестированная совместимость c IP-оборудованием марок AXIS, Smartec, Pelco by Schneider Electric, GANZ/CBC Group, Hitron, Videotec и многих других
            </li>
            <li>
                Полностью российская разработка, локальная поддержка и обучение пользователей и инсталляторов
            </li>
        </ul>
    </LandingSection>
);

export default Section2;
