import React from 'react';
import s from './Mobile.module.scss';
import cx from "classnames";
import slide1 from "./slides/360_slide1.png";
import slide2 from "./slides/360_slide2.png";
import slide3 from "./slides/360_slide3.png";
import Switcher from "./Switcher/Switcher";

const slides = [slide1, slide2, slide3];

const texts = [{
    top: 'Обеспечивает удобство централизованного администрирования в сочетании с надежностью децентрализованной системы.',
    bottom: <small>
        Система видеонаблюдения<br /> COMPASS состоит из<br /> унифицированного набора программных модулей и<br /> компонентов для ее построения.
    </small>
}, {
    top: <small>Возможность логического объединения станций COMPASS в произвольном порядке позволяет организовывать одноранговые, пересекающиеся, иерархичные сайты.</small>,
    bottom: <small>Это дает непревзойденное удобство использования единого решения для построения любых систем — от простейших односерверных до мультисерверных, корпоративного класса.</small>
}, {
    top: 'Размеры и логическая конфигурация системы не имеют значения.',
    bottom: <small>Одно рабочее место может использоваться как для удаленного администрирования всей системы в целом и любого из ее элементов, так и для работы оператора.</small>
}];

class Mobile extends React.Component {
    state = {
        slide: 0
    };

    get nextSlide() {
        const next = this.state.slide + 1;
        return next >= slides.length ? 0 : next
    }

    setNextSlide = () => this.setState({ slide: this.nextSlide });

    render() {
        const { slide } = this.state;

        return (
            <div className={cx(s.container, s[`slide${slide}`])}>
                {texts.map((text, i) => [<div className={cx(s.topText, {
                    [s.currentSlide]: i === slide
                })}>
                    {text.top}
                </div>, <div className={cx(s.bottomText, {
                    [s.currentSlide]: i === slide
                })}>
                    {text.bottom}
                </div>])}
                {slides.map((slideSrc, i) => <img
                    onClick={this.setNextSlide}
                    key={i}
                    className={cx(s.slide, {
                        [s.currentSlide]: i === slide
                    })}
                    src={slideSrc}
                />)}
                <Switcher selected={slide} onChange={slide => this.setState({ slide })} />
            </div>
        );
    }
}

export default Mobile;
