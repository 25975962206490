import React from 'react';
import LandingSection from "../../../Components/LandingSection/LandingSection";
import adaptive from "../../../Components/adaptive";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";

class Section3 extends React.Component {
    state = {
        slide: 1
    };

    nextSlide = () =>  {
        const next = this.state.slide + 1;
        this.setState({ slide: next > 3 ? 1 : next})
    };

    render() {
        const { isMobile } = this.props;

        return (
            <LandingSection title="Гибкая архитектура">
                {isMobile ? <Mobile/> : <Desktop/>}
            </LandingSection>
        );
    }
}

export default adaptive(Section3);
