import React from 'react';
import cx from 'classnames';
import Item from './Item/Item';
import s from './Desktop.module.scss';
import slide1 from "./slides/slide1.png";
import slide2 from "./slides/slide2.png";
import slide3 from "./slides/slide3.png";
import slide4 from "./slides/slide4.png";

const slides = [slide1, slide2, slide3, slide4];

class Desktop extends React.Component {
    state = {
        slide: 0,
        visible: 0
    };

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ slide: this.nextSlide }), 2000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    get nextSlide() {
        const next = this.state.slide + 1;
        return next >= slides.length ? 0 : next
    }

    setVisible = (i, isVisible) => this.setState({ visible: isVisible ? Math.max(i, this.state.visible) : Math.min(this.state.visible, i - 1) });


    render() {
        const { slide, visible } = this.state;

        return (
            <div className={s.container}>
                <div className={s.topText}>
                    Обеспечивает удобство централизованного администрирования в сочетании с надежностью децентрализованной системы.
                </div>
                {slides.map((slideSrc, i) => <img
                    key={i}
                    className={cx(s.slide, {
                        [s.currentSlide]: i === slide
                    })}
                    src={slideSrc}
                />)}
                <ul className={s[`visible${visible}`]}>
                    <Item onChange={this.setVisible} i={1} visible={visible}>
                        Система видеонаблюдения COMPASS состоит из унифицированного набора программных модулей и компонентов для ее построения.
                    </Item>
                    <Item onChange={this.setVisible} i={2} visible={visible}>
                        Возможность логического объединения станций COMPASS в произвольном порядке позволяет организовывать одноранговые, пересекающиеся, иерархичные сайты.
                    </Item>
                    <Item onChange={this.setVisible} i={3} visible={visible}>
                        Это дает непревзойденное удобство использования единого решения для построения любых систем — от простейших односерверных до мультисерверных, корпоративного класса.
                    </Item>
                    <Item onChange={this.setVisible} i={4} visible={visible}>
                        Все модули в системе равноценны и могут выполнять одинаковые функции.
                        <small>
                            Это позволяет отказаться от использования центрального управляющего компонента (сервера), влияющего на работу всей системы в целом, повышает ее надежность и живучесть. Неисправность одного или нескольких элементов не ведет к выходу из строя всей системы. Она продолжает функционировать.
                        </small>
                    </Item>
                    <Item onChange={this.setVisible} i={5} visible={visible}>
                        Размеры и логическая конфигурация системы не имеют значения.
                    </Item>
                    <Item onChange={this.setVisible} i={6} visible={visible}>
                        Одно рабочее место может использоваться как для удаленного администрирования всей системы в целом и любого из ее элементов, так и для работы оператора.
                    </Item>
                </ul>
            </div>
        );
    }
}

export default Desktop;

