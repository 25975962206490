import React from 'react';
import s from './Switcher.module.scss';
import cx from "classnames";

const Switcher = ({ onChange, selected }) => (
    <div className={cx(s.container, s[`s${selected}`])}>
        <Point number={0} onChange={onChange} selected={selected} />
        <Point number={1} onChange={onChange} selected={selected} />
        <Point number={2} onChange={onChange} selected={selected} />
    </div>
);

const Point = ({ onChange, selected, number }) => (
    <div className={s.pointWrapper} onClick={() => onChange(number)}>
        <div
            className={cx(s.point, number === selected && s.selected)}
        />
    </div>
);

export default Switcher;
