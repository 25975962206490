import React from 'react';
import autosize from 'autosize';
import s from './Section7.module.scss';
import LandingSection from "../../../Components/LandingSection/LandingSection";
import {postAjax} from "../../../Helpers/RequestsHelper";

class Section7 extends React.Component {
    state = {};

    componentDidMount() {
        autosize(this.textArea);
    }

    onSubmit = e => {
        this.setState({ error: false });
        postAjax(
            `${window.location.protocol}//${window.location.hostname}/send`,
            this.state,
            () => this.setState({ sent: true }),
            () => this.setState({ error: true })
        );
        e.preventDefault();
    };

    updateValue = name => e => this.setState({ [name]: e.currentTarget.value });

    render() {
        const { sent, error } = this.state;
        return (
            <LandingSection id="landingForm" className={s.container} title="Обратная связь">
                {sent ? <div className={s.success}>
                        Ваше сообщение отправлено.
                    </div>
                    : <form onSubmit={this.onSubmit}>
                        <div className={s.row}>
                            <input onChange={this.updateValue('name')} required placeholder="Имя"/>
                            <input onChange={this.updateValue('email')} required type="email" placeholder="E-mail"/>
                        </div>
                        <div className={s.row}>
                            <textarea onChange={this.updateValue('message')}  required rows={1} ref={e => this.textArea = e} placeholder="Сообщение"/>
                        </div>
                        {error && <div className={s.error}>
                            На сервере произошла ошибка, попробуйте отправить ещё раз позднее.
                        </div>}
                        <button>отправить</button>
                </form>}
            </LandingSection>
        );
    }
}

export default Section7;
