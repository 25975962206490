import React from 'react';
import s from './Section1.module.scss';
import poin1 from './point1.png';
import poin2 from './point2.png';
import poin1_1300 from './point1_1300.png';
import poin2_1300 from './point2_1300.png';
import point1_360 from './point1_360.png';
import screen from './screen.png';
import adaptive from "../../../Components/adaptive";

const Section1 = ({ isNotebook, isMobile }) => (
    <div className={s.container}>
        <div className={s.text}>
            Профессиональное программное обеспечение, созданное для {isNotebook && !isMobile && <br />}построения больших распределенных гибридных/IP систем {isNotebook && !isMobile && <br />}видеонаблюдения
            <img className={s.point1} src={isNotebook ? isMobile ? point1_360 : poin1_1300 : poin1} />
        </div>
        {isMobile || <img className={s.screen} src={screen} />}
        <div className={s.text}>
            Программный продукт разработан отечественными специалистами с многолетним опытом создания решений для обеспечения безопасности объектов любого масштаба при активном участии профессионалов отечественного рынка систем безопасности.
            {isMobile || <img className={s.point2} src={isNotebook ? poin2_1300 : poin2} />}
        </div>
    </div>
);

export default adaptive(Section1);
