import React from 'react';

function isElementInCenter(el) {
    var rect = el.getBoundingClientRect();

    var distance = window.innerHeight / 2 - rect.top;

    return distance >= 0 && distance < rect.height;
}

class Item extends React.Component {
    state = {
        hover: false,
        inCenter: false
    };

    componentDidMount() {
        this.container.onmouseenter = () => this.setState({hover: true});
        this.container.onmouseleave = () => this.setState({hover: false});
        window.addEventListener('scroll', () => this.setState({inCenter: isElementInCenter(this.container)}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hover !== this.state.hover) {
            this.props.parent.setState({itemHovered: this.state.hover});
        }
    }

    render() {
        const {className, children, parent} = this.props;
        const { hover, inCenter } = this.state;

        return (
            <div ref={e => this.container = e} className={className}>
                {children(parent.state.itemHovered ? hover : inCenter)}
            </div>
        );
    }
}

export default Item;
