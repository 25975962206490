import React from 'react';
import cx from 'classnames';
import s from './LandingSection.module.scss';

const LandingSection = ({ title, children, className, ...props }) => (
    <div className={cx(s.container, className)} {...props}>
        <div className={s.title}>
            {title}
        </div>
        <div className={s.content}>
            {children}
        </div>
    </div>
);

export default LandingSection;
